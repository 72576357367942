.container {
    margin: 50px 0;
}

.card {
    margin: 0 2% !important;
}

.infected {
    border-bottom: 10px solid rgba(196, 149, 33, 0.5);
}

.recovered {
    border-bottom: 10px solid rgba(33, 194, 196, 0.5);
}

.deaths {
    border-bottom: 10px solid rgba(144, 12, 63, 0.5);
}

.loading {
    width: 200px;
    margin: 30px 30px;
}

@media (max-width: 770px) {
    .container {
        margin: 0 5%;
    }

    .card {
        margin: 2.5% 0 !important;
    }
}