.container {
    display: flex;
    justify-content: center;
    width: 85%;
    flex-direction: column;
}

.lineChart, .barChart {
    margin-bottom: 30px;
}

@media (max-width: 770px) {
    .container {
        /* For small devices charts should have 100% width, else the vertical numbers will collapse */
        width: 100%;
    }
}