body {
    background-color: rgb(250, 250, 250);
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image {
    width: 200px;
    margin: 30px 0;
}

/*
@media (max-width: 770px) {
    .container {
        margin: 0 2%;
    }

    .image {
        width: 100%;
    }
}
*/